import { combineReducers } from "redux";
import {
  MonitoringRiskMitigationRCSACommand,
  MonitoringRiskMitigationRCSADefault,
  MonitoringRiskMitigationRCSAReducer,
} from "./risk-mitigation-rcsa/reducer";
import { MonitoringModel } from "./types";
import {
  MonitoringRiskMitigationLEDCommand,
  MonitoringRiskMitigationLEDDefault,
  MonitoringRiskMitigationLEDReducer,
} from "./risk-mitigation-led/reducer";

const MonitoringReducer = combineReducers({
  riskMitigationRCSA: MonitoringRiskMitigationRCSAReducer,
  riskMitigationLED: MonitoringRiskMitigationLEDReducer,
});

const MonitoringDefault: MonitoringModel = {
  riskMitigationRCSA: MonitoringRiskMitigationRCSADefault,
  riskMitigationLED: MonitoringRiskMitigationLEDDefault,
};

const MonitoringCommand = {
  riskMitigationRCSA: MonitoringRiskMitigationRCSACommand,
  riskMitigationLED: MonitoringRiskMitigationLEDCommand,
};

export { MonitoringCommand, MonitoringDefault, MonitoringReducer };
