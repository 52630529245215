import { TAction, TDispatch } from "../../../types";
import {
  ConfigureAuditTrailsAction,
  ConfigureAuditTrailsActionType,
  ConfigureAuditTrailsModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";

export const ConfigureAuditTrailsDefault: ConfigureAuditTrailsModel = {
  activityLogs: {
    main: {
      data: [],
      total: 0,
    },
  },
  systemLogs: {
    main: {
      data: [],
      total: 0,
    },
  },
  systemLogsFile: {
    main: {
      data: [],
      total: 0
    }
  }
};

export const ConfigureAuditTrailsReducer = (
  state: ConfigureAuditTrailsModel = ConfigureAuditTrailsDefault,
  action: ConfigureAuditTrailsAction
): ConfigureAuditTrailsModel => {
  switch (action.type) {
    case ConfigureAuditTrailsActionType.GetActivityLogsMain:
      return {
        ...state,
        activityLogs: { ...state.activityLogs, main: action.data },
      };
    case ConfigureAuditTrailsActionType.GetSystemLogsMain:
      return {
        ...state,
        systemLogs: { ...state.systemLogs, main: action.data },
      };
    case ConfigureAuditTrailsActionType.GetSystemLogFiles:
      return {
        ...state,
        systemLogsFile: { ...state.activityLogs, main: action.data },
      };

    default:
      return { ...state };
  }
};

export const ConfigureAuditTrailsCommand = {
  GetActivityLogsMainData: (params?: any): TAction<ConfigureAuditTrailsAction, void> => {
    return (dispatch: TDispatch<ConfigureAuditTrailsAction>) => {
      return fetchAPI().get('/activities', { params }).then((response) => {
        const data = response.data.data;
        dispatch({
          data: {
            data: data.data,
            total: data.total,
          },
          type: ConfigureAuditTrailsActionType.GetActivityLogsMain,
        });
      });
    };
  },
  GetSystemLogsMainData: (params?: any): TAction<ConfigureAuditTrailsAction, void> => {
    return (dispatch: TDispatch<ConfigureAuditTrailsAction>) => {
      return fetchAPI().get('/logs', { params }).then((response) => {
        const data = response.data.data;
        dispatch({
          data: {
            data: data.data,
            total: data.total,
          },
          type: ConfigureAuditTrailsActionType.GetSystemLogsMain,
        });
      });
    };
  },
  GetSystemLogFiles: (): TAction<ConfigureAuditTrailsAction, void> => {
    return (dispatch: TDispatch<ConfigureAuditTrailsAction>) => {
      return fetchAPI().get('/log/files').then((response) => {
        const data = response.data.data;
        dispatch({
          data: {
            data: data.data,
            total: data.total,
          },
          type: ConfigureAuditTrailsActionType.GetSystemLogFiles,
        });
      });
    };
  },
  DownloadSystemLog: (params?: any): TAction<ConfigureAuditTrailsAction, void> => {
    return async (dispatch: TDispatch<ConfigureAuditTrailsAction>) => {
      const response = await fetchAPI().get(`/log/download?file=${params.file}`, {
        responseType: 'blob', // Set responseType to 'blob' to handle binary data
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a link element
      const link = document.createElement('a');

      // Set the download attribute and create a URL for the blob
      link.href = window.URL.createObjectURL(blob);
      link.download = 'system_log.xlsx'; // Set the desired file name

      // Append the link to the document body and programmatically click it
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);
    };
  },
  DownloadActivityLog: (params?: any): TAction<ConfigureAuditTrailsAction, void> => {
    return async (dispatch: TDispatch<ConfigureAuditTrailsAction>) => {
      const response = await fetchAPI().get(`/activities/download?date_start=${params.date_start}&date_end=${params.date_end}`, {
        responseType: 'blob', // Set responseType to 'blob' to handle binary data
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a link element
      const link = document.createElement('a');

      // Set the download attribute and create a URL for the blob
      link.href = window.URL.createObjectURL(blob);
      link.download = 'activity_log.xlsx'; // Set the desired file name

      // Append the link to the document body and programmatically click it
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);
    };
  },
};
