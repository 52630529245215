import { TAction, TDispatch } from "../../../types";
import {
  ReportRiskProfileAction,
  ReportRiskProfileActionType,
  ReportRiskProfileModel,
  ReportRiskProfileFilterDataModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";

export const ReportRiskProfileDefault: ReportRiskProfileModel = {
  main: {
    data: [] as any,
    total: 0,
  },
  riskProfileFilter: {
    data: {} as ReportRiskProfileFilterDataModel,
  },
};

export const ReportRiskProfileReducer = (
  state: ReportRiskProfileModel = ReportRiskProfileDefault,
  action: ReportRiskProfileAction
): ReportRiskProfileModel => {
  switch (action.type) {
    case ReportRiskProfileActionType.GetRiskProfileFilter:
      return { ...state, riskProfileFilter: action.data };
    case ReportRiskProfileActionType.GetRiskProfile:
      return { ...state, main: action.data };
    default:
      return { ...state };
  }
};

export const ReportRiskProfileCommand = {
  GetRiskProfileFilter: (
    body: unknown
  ): TAction<ReportRiskProfileAction, void> => {
    return (dispatch: TDispatch<ReportRiskProfileAction>) => {
      return fetchAPI()
        .post("/report/risk-profile/filters", { filter: body })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data,
            },
            type: ReportRiskProfileActionType.GetRiskProfileFilter,
          });
        });
    };
  },
  GetRiskProfile: (
    params: unknown,
    body: unknown
  ): TAction<ReportRiskProfileAction, void> => {
    return (dispatch: TDispatch<ReportRiskProfileAction>) => {
      return fetchAPI()
        .post("/report/risk-profiles", { filter: body }, { params })
        .then((response) => {
          const data = response.data.data;
          dispatch({
            data: {
              data: data.data,
              total: data.total,
            },
            type: ReportRiskProfileActionType.GetRiskProfile,
          });
        });
    };
  },
};
