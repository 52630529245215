import { AxiosResponse } from "axios";
import { fetchAPI } from "../../../../config/axios";
import { TAction, TDispatch } from "../../../types";
import {
  MonitoringRiskMitigationLEDAction,
  MonitoringRiskMitigationLEDActionType,
  MonitoringRiskMitigationLEDDependencyDataModel,
  MonitoringRiskMitigationLEDDetailDataModel,
  MonitoringRiskMitigationLEDModel,
} from "./types";

export const MonitoringRiskMitigationLEDDefault: MonitoringRiskMitigationLEDModel =
  {
    main: {
      data: [] as any,
      total: 0,
    },
    detail: {
      data: {} as MonitoringRiskMitigationLEDDetailDataModel,
    },
    dependency: {
      data: {} as MonitoringRiskMitigationLEDDependencyDataModel,
    },
  };

export const MonitoringRiskMitigationLEDReducer = (
  state: MonitoringRiskMitigationLEDModel = MonitoringRiskMitigationLEDDefault,
  action: MonitoringRiskMitigationLEDAction
): MonitoringRiskMitigationLEDModel => {
  switch (action.type) {
    case MonitoringRiskMitigationLEDActionType.GetMonitoringRiskMitigationLED:
      return { ...state, main: action.data };
    case MonitoringRiskMitigationLEDActionType.GetMonitoringRiskMitigationLEDDetail:
      return { ...state, detail: action.data };
    case MonitoringRiskMitigationLEDActionType.GetMonitoringRiskMitigationLEDDependency:
      return { ...state, dependency: action.data };
    default:
      return { ...state };
  }
};

export const MonitoringRiskMitigationLEDCommand = {
  GetAllMonitoringLedMitigation: (
    params?: unknown
  ): TAction<MonitoringRiskMitigationLEDAction, void> => {
    return (dispatch: TDispatch<MonitoringRiskMitigationLEDAction>) => {
      return fetchAPI()
        .get("/monitoring-led-mitigations", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data.data,
              total: response.data.data.total,
            },
            type: MonitoringRiskMitigationLEDActionType.GetMonitoringRiskMitigationLED,
          });
        });
    };
  },
  GetDetailMonitoringLedMitigation: (
    params?: unknown
  ): TAction<MonitoringRiskMitigationLEDAction, void> => {
    return (dispatch: TDispatch<MonitoringRiskMitigationLEDAction>) => {
      return fetchAPI()
        .get("/monitoring-led-mitigation", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data,
            },
            type: MonitoringRiskMitigationLEDActionType.GetMonitoringRiskMitigationLEDDetail,
          });
        });
    };
  },
  GetDependencyMonitoringLedMitigation: (
    params?: unknown
  ): TAction<MonitoringRiskMitigationLEDAction, void> => {
    return (dispatch: TDispatch<MonitoringRiskMitigationLEDAction>) => {
      return fetchAPI()
        .get("/monitoring-led-mitigation/dependency", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data,
            },
            type: MonitoringRiskMitigationLEDActionType.GetMonitoringRiskMitigationLEDDependency,
          });
        });
    };
  },
  UpdateMonitoringLedMitigation: (
    body: any
  ): TAction<MonitoringRiskMitigationLEDAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<MonitoringRiskMitigationLEDAction>) => {
      try {
        const response = await fetchAPI().post(
          "/monitoring-led-mitigation",
          body
        );
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  ApprovalMonitoringLedMitigation: (
    body?: any
  ): TAction<MonitoringRiskMitigationLEDAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<MonitoringRiskMitigationLEDAction>) => {
      try {
        const url =
          body.type === "approve"
            ? "/monitoring-led-mitigation/submit"
            : "/monitoring-led-mitigation/reject";
        const response = await fetchAPI().post(url, body.body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
