import { AxiosResponse } from "axios";
import { fetchAPI } from "../../../../config/axios";
import { TAction, TDispatch } from "../../../types";
import {
  MonitoringRiskMitigationRCSAAction,
  MonitoringRiskMitigationRCSAActionType,
  MonitoringRiskMitigationRCSADependencyDataModel,
  MonitoringRiskMitigationRCSADetailDataModel,
  MonitoringRiskMitigationRCSAModel,
} from "./types";

export const MonitoringRiskMitigationRCSADefault: MonitoringRiskMitigationRCSAModel =
  {
    main: {
      data: [] as any,
      total: 0,
    },
    detail: {
      data: {} as MonitoringRiskMitigationRCSADetailDataModel,
    },
    dependency: {
      data: {} as MonitoringRiskMitigationRCSADependencyDataModel,
    },
  };

export const MonitoringRiskMitigationRCSAReducer = (
  state: MonitoringRiskMitigationRCSAModel = MonitoringRiskMitigationRCSADefault,
  action: MonitoringRiskMitigationRCSAAction
): MonitoringRiskMitigationRCSAModel => {
  switch (action.type) {
    case MonitoringRiskMitigationRCSAActionType.GetMonitoringRiskMitigationRCSA:
      return { ...state, main: action.data };
    case MonitoringRiskMitigationRCSAActionType.GetMonitoringRiskMitigationRCSADetail:
      return { ...state, detail: action.data };
    case MonitoringRiskMitigationRCSAActionType.GetMonitoringRiskMitigationRCSADependency:
      return { ...state, dependency: action.data };
    default:
      return { ...state };
  }
};

export const MonitoringRiskMitigationRCSACommand = {
  GetAllMonitoringRiskMitigation: (
    params?: unknown
  ): TAction<MonitoringRiskMitigationRCSAAction, void> => {
    return (dispatch: TDispatch<MonitoringRiskMitigationRCSAAction>) => {
      return fetchAPI()
        .get("/monitoring-risk-mitigations", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data.data,
              total: response.data.data.total,
            },
            type: MonitoringRiskMitigationRCSAActionType.GetMonitoringRiskMitigationRCSA,
          });
        });
    };
  },
  GetDetailMonitoringRiskMitigation: (
    params?: unknown
  ): TAction<MonitoringRiskMitigationRCSAAction, void> => {
    return (dispatch: TDispatch<MonitoringRiskMitigationRCSAAction>) => {
      return fetchAPI()
        .get("/monitoring-risk-mitigation", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data,
            },
            type: MonitoringRiskMitigationRCSAActionType.GetMonitoringRiskMitigationRCSADetail,
          });
        });
    };
  },
  GetDependencyMonitoringRiskMitigation: (
    params?: unknown
  ): TAction<MonitoringRiskMitigationRCSAAction, void> => {
    return (dispatch: TDispatch<MonitoringRiskMitigationRCSAAction>) => {
      return fetchAPI()
        .get("/monitoring-risk-mitigation/dependency", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data,
            },
            type: MonitoringRiskMitigationRCSAActionType.GetMonitoringRiskMitigationRCSADependency,
          });
        });
    };
  },
  UpdateMonitoringRiskMitigation: (
    body: any
  ): TAction<MonitoringRiskMitigationRCSAAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<MonitoringRiskMitigationRCSAAction>) => {
      try {
        const response = await fetchAPI().post(
          "/monitoring-risk-mitigation",
          body,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  ApprovalMonitoringRiskMitigation: (
    body?: any
  ): TAction<MonitoringRiskMitigationRCSAAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<MonitoringRiskMitigationRCSAAction>) => {
      try {
        const url =
          body.type === "approve"
            ? "/monitoring-risk-mitigation/submit"
            : "/monitoring-risk-mitigation/reject";
        const response = await fetchAPI().post(url, body.body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
