import { combineReducers } from "redux";
import { ReportModel } from "./types";
import {
  ReportRiskProfileCommand,
  ReportRiskProfileDefault,
  ReportRiskProfileReducer,
} from "./risk-profile/reducers";

const ReportReducer = combineReducers({
  riskProfile: ReportRiskProfileReducer,
});

const ReportDefault: ReportModel = {
  riskProfile: ReportRiskProfileDefault,
};

const ReportCommand = {
  riskProfile: ReportRiskProfileCommand,
};

export { ReportReducer, ReportDefault, ReportCommand };
